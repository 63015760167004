import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import MenuMobileSubmenu from './menu-mobile-submenu'
import { MenuWrap, Instagram, Bottom } from './index.styled'
import { Logo, Primary, Li, Item } from '../index.styled'

const MenuMobile = ({ onClose }) => {
  const [submenu, setSubmenu] = React.useState(null)

  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuMobile2Query {
      menuContent {
        content {
          secondary {
            text
            url
          }
          primary {
            text
            url
            submenu {
              text
              url
              type
            }
          }
        }
      }
    }
  `)

  const submenuOpen = (menu) => {
    setSubmenu(menu)
    document.querySelector('.home-wrapper').classList.add('open-submenu')
  }
  const submenuClose = () => {
    document.querySelector('.home-wrapper').classList.remove('open-submenu')
    setSubmenu(null)
  }

  const onCloseFull = () => {
    onClose()
    submenuClose()
  }

  return (
    <>
      <MenuWrap>
        <Logo to="/" onClick={onCloseFull} />
        <Primary>
          {links.primary.map((item, index) => (
            <Li
              key={`primary-item-${index}`}
              onClick={
                item.submenu
                  ? () => {
                      submenuOpen(item.submenu)
                    }
                  : onCloseFull
              }
              className={[].join(' ')}
            >
              {item.submenu ? (
                <Item as="span">{item.text}</Item>
              ) : (
                <Item to={item.url}>{item.text}</Item>
              )}
            </Li>
          ))}
          {links.secondary.map((item, index) => (
            <Li key={`secondary-item-${index}`} onClick={onCloseFull}>
              <Item to={item.url}>{item.text}</Item>
            </Li>
          ))}
        </Primary>
        <Bottom>
          <Instagram
            href="https://www.instagram.com/williamsburgwharf/"
            target="_blank"
          />

          <div>
            <Link to="/legal/" onClick={onCloseFull}>
              Legal
            </Link>
            <a href="/fair-housing-notice.pdf" target="_blank">
              fair housing notice
            </a>
          </div>

          <a href="/NY-StandardOperatingProcedures.pdf" target="_blank">
            standard operating procedures
          </a>
        </Bottom>
      </MenuWrap>
      <MenuMobileSubmenu
        submenu={submenu || []}
        onClose={onCloseFull}
        onBack={submenuClose}
      />
    </>
  )
}

export default MenuMobile
